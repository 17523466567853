import React, { useEffect, useState } from 'react';
import '../sections/message/message.css'
import moment from "moment";
import ReactAudioPlayer from 'react-audio-player';
import { auth, db, realTimeDatabase } from "../firebase/firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom';
import { useForm } from "../contexts/formContext";
import { ModalComponent } from "../sections/modal/modal";


export const Modal = ({ src, onClose, type }) => {

    return (
        <div className="media-modal">
            <span className="close" onClick={onClose}>
                &times;
            </span>
            {type === 'image' ? <img className="media-modal-content" src={src} />
                : type === 'video' ? <video className="media-modal-content" src={src} preload={`true`} autoPlay controls />
                    :
                    <></>
            }
        </div>
    )
}


const Text = ({ message, preview, participants }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [eventId, setEventId] = useState("");
    const [chatRoomId, setChatRoomId] = useState("");
    const [user] = useAuthState(auth);
    const showModal = () => setIsOpen(true)
    const history = useHistory()
    const { setFormType } = useForm();
    const [scheduleDateTimeData, setScheduleDateTimeData] = useState({});
    const [eventData, setEventData] = useState({});
    const [activeTab, setActiveTab] = useState({});
    const [scheduleData, setScheduleData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        db.collection("Schedules").doc(message.exhibitionId).collection(message.exhibitionRound).doc(message.roundDocId).get().then((schedule)=>{
            setScheduleData(schedule.data());
        })
    }, [message, loading])
    
    // console.log(message.deliveredToParticipants.length, participants)
    const AcceptHandler = async () => {
        setLoading(true);
        var updateScheduleData = {};

        if(auth.currentUser.uid == scheduleData.FirstUserId){
            updateScheduleData.FirstUserTimeProposal = scheduleData.SecondUserTimeProposal;
            updateScheduleData.AgreedDateTime = scheduleData.SecondUserTimeProposal;
        }
        else{
            updateScheduleData.SecondUserTimeProposal = scheduleData.FirstUserTimeProposal;
            updateScheduleData.AgreedDateTime = scheduleData.FirstUserTimeProposal;
        }
        await db.collection("Schedules").doc(message.exhibitionId).collection(message.exhibitionRound).doc(message.roundDocId).update(updateScheduleData);
        setLoading(false);
    }

    // const onChangeTimeHandler = () => {
    //     console.log("Message", message);
    //     // const history = useHistory()
    //     // window.location.href =
    //     history.push({
    //         pathname: `/user/${user.uid}/tournament/${message.chatRoomId}`,
    //         search: '?openModel=true',
    //         state: { openModel: true }
    //     });
    // }

    const onChangeTimeHandler = async () => {
        
        history.push(`/user/${user.uid}/tournament/${message.exhibitionId}`);
        // alert("Hello there");
        // let evntData = {ExhibitionName: message.exhibitionName, exhibitionRound: message.exhibitionRound};
        // let chatRoomData = {docId: message.roundDocId};

        // if (message.firstUserTimeProposal) {
        //     chatRoomData.FirstUserId = message.senderObjId;
        //     chatRoomData.SecondUserId = user.uid;
        // }
        // else {
        //     chatRoomData.FirstUserId = user.uid;
        //     chatRoomData.SecondUserId = message.senderObjId;
        // }
        // await setEventId(message.exhibitionId);
        // await setChatRoomId(message.chatRoomId);
        // await setScheduleDateTimeData(chatRoomData);
        // await setEventData(evntData);
        // await setActiveTab(message.exhibitionRound);
        // await setFormType('eventScoreDateTime');
        // await setModalShow(true);
        // console.log("hello scheduleDateTimeData",scheduleDateTimeData);
        // console.log("hello evntData",evntData);
    }
    return (
        <>
            <div className='chat-text-container d-flex align-items-end flex-column'>
                <span className={`align-self-start sender-name font-weight-bold`}> {message.senderName}</span>
                <span className={`align-self-start text-break`}>

                    <div className='d-flex flex-cloumn'>
                        {message.text !== '' ?
                            <p className='mb-1 mt-1'>{message.text}</p> :
                                message.isImage || preview ?
                                    <img className="image" onClick={showModal} src={message.storageMediaUrl} alt="" />
                                    :
                                    message.isVideo || preview ?
                                        <video onClick={showModal} className="image" src={message.storageMediaUrl || preview} />
                                        :
                                        message.isVoiceNote || preview ?
                                            message.storageMediaUrl ? <ReactAudioPlayer
                                                src={message.storageMediaUrl || preview}
                                                controls
                                                className={'audio'}
                                            /> :
                                                <audio controls className="audio" src={''} />
                                            :
                                            <></>}
                    </div>

                </span>
                {
                    (message?.exhibitionId && message?.exhibitionId != "") ?
                        (scheduleData?.AgreedDateTime)?
                        <div className='w-100 justify-content-around flex-row d-flex'>
                            <div className='pointer' style={{ minHeight: 0, float: 'left' }}>
                                <p className='text-center mt-1 mb-2' style={{ color: '#18ff00' }}>Accepted</p>
                            </div>
                        </div>
                        :
                        (message?.senderObjId == user.uid)?
                        <div className='w-100 justify-content-around flex-row d-flex'>
                            <div className='pointer' style={{ minHeight: 0, float: 'left' }}>
                                <p className='text-center mt-1 mb-2' style={{ color: '#18ff00' }}>Request Sent</p>
                            </div>
                        </div>
                        :
                        <div className='w-100 justify-content-around flex-row d-flex'>
                            <div onClick={() => { AcceptHandler() }} className='pointer' style={{ minHeight: 0, float: 'left' }}>
                                <p className='text-center mt-1 mb-2' style={{ color: '#18ff00' }}>Accept</p>
                            </div>
                            <div onClick={() => { onChangeTimeHandler() }} className='pointer' style={{ float: 'right' }}>
                                <p className='text-center mt-1 mb-2' style={{ color: '#18ff00' }}>Change Time</p>
                            </div>
                        </div>
                    :
                    <></>
                }
                <div className={`d-flex align-items-center align-self-end m-0 p-0`}>
                    <small className={`delivered-date  mr-2`}>{message.dateTime && moment(message.dateTime?.seconds * 1000).format("DD/MM/YYYY h:mm")}</small>
                    <div className={`position-relative d-flex`}>
                        <span className={`delivered-ticks `}><small className={``}>&#10003;</small>
                            {message.deliveredToParticipants.length === participants && <small className={``}>&#10003;</small>}
                        </span>
                    </div>
                </div>
                {isOpen &&
                    <Modal
                        src={message.storageMediaUrl}
                        type={message.isImage ? 'image' : message.isVideo ? 'video' : ''}
                        onClose={() => setIsOpen(false)}
                    />}

                <ModalComponent
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    scheduleData={scheduleDateTimeData}
                    eventId={eventId}
                    eventData={eventData}
                    activeTab={activeTab}
                    chatRoomId={chatRoomId}
                />
            </div>
        </>


    );
}
    ;

export default Text;