import React, { useEffect, useRef, useState } from 'react';
import basketball from "../images/basketball.png";
import schedule from "../images/created_schedule.png";
import trophy from "../images/trophy.png";
import participants from "../images/participants.png";
import nike from "../images/nike-squarelogo-1486596898031.png";
import { Modal } from "react-bootstrap";
import { db } from "../firebase/firebase";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore"
import { pushRealTimeData, timeConverter, updateFirebaseDocument, updateFirestoreDocument } from '../utility/helper'
import '../sections/events/eventDetails.css'
import { useHistory, useParams } from "react-router-dom";
import logo from '../images/logo.png';
import { useLoader } from "../contexts/loaderContext";
import BackButton from "./backButton";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import { useStateValue } from "../contexts/StateProvider";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, realTimeDatabase } from "../firebase/firebase";
import UserImage from '../images/default_profile_photo.svg';
import { useForm } from "../contexts/formContext";
import { ModalComponent } from "../sections/modal/modal";
// import '../sections/modal/modal.css';

const difficulty = {
    easy: '#0E7A0D',
    hard: '#d52000',
    veryHard: '#D50000',
    normal: '#D48600',
    playstation: '#006FCD',

}

function ModalInviteCredit(props) {
    const [{ userData }] = useStateValue()
    const modalSubmit= async ()=>{

        props.onHide();
        props.handleJoinEvent();
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={`true`}
            dialogClassName="modal"
            className={`custom-modal`}
        >

            <Modal.Body>
            <form className="form">
                <div class="form-check d-flex flex-row justify-content-center align-item-center mt-3" style={{padding: "0px"}}>
                    <input class="mt-1" style={{width:'1.2rem',height:'1.2rem', margin:0, minHeight:'auto'}} type="checkbox" disabled={!(("InviteCredits" in userData) && (userData.InviteCredits > 0))} value="checked" id="flexCheckDefault" onChange={(e)=>props.setUseInviteCreditsChecked(e.target.checked)}/>
                    <label class="ml-3" for="flexCheckDefault" style={{fontSize:'1.2rem'}}>
                        {`Use $${userData.InviteCredits} Invite Credit`}
                    </label>
                </div>
                <div className='d-flex flex-row justify-content-center align-item-center'>
                    <button   style={{ width: '42%', maxHeight: '36px', borderRadius: '10px', background: '#ffffff', textTransform: 'capitalize' }} type='button' onClick={modalSubmit}>
                        <span className='form-btn' style={{fontSize: "10pt"}}>{'Submit'}</span>
                    </button>
                </div>
            </form>
            </Modal.Body>

        </Modal>
    );
}

function ModalPopup(props) {
    const override = css`
      display: block;
      margin: 0 7%;`;
    let [color, setColor] = useState("#18ff00");
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={`true`}
            className={`event-popup`}
        >

            <Modal.Body>
                <div className={` d-flex p-3 justify-content-center`}>

                    {props.error ? <span className={`text-danger`}>{props.error}</span> : <>
                        <PuffLoader css={override} loading={props.loading} size={24} color={color} />
                        <span className={`text-light`}> Joining exhibition...</span>

                    </>}

                </div>

            </Modal.Body>

        </Modal>
    );
}


function EventSection({ event, participantsList, hasJoined, isLoading }) {
    const commisionerId = useRef()
    const [currentUser] = useAuthState(auth)
    const [{ userData }] = useStateValue()
    const { loader } = useLoader();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const secondCommissionerId = useRef()
    const startDate = timeConverter(parseInt(event?.ExhibitionStartDate), 'D-M-Y');
    const endDate = timeConverter(parseInt(event?.ExhibitionEndDate), 'D-M-Y');
    let params = useParams();
    const history = useHistory();
    const { setFormType } = useForm();
    const [modalShow, setModalShow] = useState(false);
    const [useInviteCreditsChecked, setUseInviteCreditsChecked ] = useState(false);
    const [inviteCreditModalShow, setInviteCreditModalShow] = useState(false);
    // console.log(userData)
    const eventId = { EventId: params.id };

    const [game, setGame] = useState({});
    const [gameCoverArt, setGameCoverArt] = useState(null);
    const [chatRooms] = useCollection(db.collection('ChatRooms').doc(params.id));
    // console.log("Name is :",participantsList[0].userName);

    const clickJoinEvent = async(e)=>{
        setFormType('inviteCredit');
        setUseInviteCreditsChecked(false);
        setInviteCreditModalShow(true);
    }

    useEffect(() => {
        if(!event.ExhibitionGameCoverArt || event.ExhibitionGameCoverArt == ""){
            db.collection('Games').where('Game', '==', event?.ExhibitionGame).get().then((gameRes)=>{
                if(gameRes.docs.length > 0){
                    var gameData = gameRes.docs[0].data();
                    if("gameCoverArt" in gameData && gameData.gameCoverArt != ""){
                        setGameCoverArt(gameData.gameCoverArt);
                    }
                    else{
                        setGameCoverArt(logo);
                    }
                    setGame(gameData);
                }
                else{
                    setGameCoverArt(logo);
                }
            }).catch((e)=>{
                setGameCoverArt(logo);
            })
        }
        else{
            setGameCoverArt(event.ExhibitionGameCoverArt);
        }
    }, [event])

    const handleJoinEvent = async (e) => {

        if(loading){
            return false;
        }
        setError('')
        setLoading(true)
        setModalShow(true)
        const deductionRate = 7.5;
        const entryFee = parseInt(event.ExhibitionEntryFee);
        const calculateFees = ((parseInt(event.ExhibitionCurrentParticipants) + 1) * entryFee);
        const priceProgressFee = calculateFees - (calculateFees * deductionRate/100);

        realTimeDatabase.ref('Participants')
            .orderByChild('ExhibitionId')
            .equalTo(params.id)
            .get()
            .then(snapshot => {
                const alreadyJoined = snapshot.forEach(doc => {
                    if (doc.val().userId === currentUser.uid)
                        return true
                    else return false
                })
                if (!alreadyJoined) {

                    var totalUserBalance = parseInt(userData.userBalance);
                    
                    if("InviteCredits" in userData){
                        totalUserBalance += parseInt(userData.InviteCredits);
                    }
                    var updateUserObj = {};

                    //Check if current user balance is more than the event entry fee
                    //Remaining balance deducted from the user balance  after Joining event
                    if(totalUserBalance >= parseInt(event?.ExhibitionEntryFee)){

                        if(useInviteCreditsChecked){
                            if(parseInt(userData.InviteCredits) >= parseInt(event?.ExhibitionEntryFee)){
                                updateUserObj = {InviteCredits: (parseInt(userData.InviteCredits) - parseInt(event?.ExhibitionEntryFee))};
                            }
                            else{
                                var difference = parseInt(event?.ExhibitionEntryFee) - parseInt(userData.InviteCredits);
                                updateUserObj = {InviteCredits: 0, userBalance: (parseInt(userData.userBalance) - difference)};
                            }
                        }
                        else{
                            if(parseInt(userData.userBalance) >= parseInt(event?.ExhibitionEntryFee)){
                                updateUserObj = {userBalance: (parseInt(userData.userBalance) - parseInt(event?.ExhibitionEntryFee))};
                            }
                            else{
                                setError('Please deposit enough funds to join the event.');
            
                                setLoading(false);
                                return false;
                            }
                        }
                        //Combine user object and eventId
                        const userObj = Object.assign({}, userData, eventId);

                        if (userObj) {
                           
                            //Update user balance in firestore User document
                            updateFirestoreDocument('User', currentUser.uid, updateUserObj)
                                .then(() => {

                                    //Update user balance in firebase User document
                                    updateFirebaseDocument('Users', currentUser.uid, updateUserObj)
                                        .then(() => {
                                            //Add current user to the firebase participants collection
                                            const { objectId, gamerTag, userProfileImageUrl } = userObj
                                            pushRealTimeData('Participants', { userId: objectId, userGamerTag: gamerTag, userProfileImageUrl: userProfileImageUrl, ExhibitionId: params.id })
                                                .then((snapshot) => {

                                                    //Update ExhibitionCurrentParticipants field in the Events document
                                                    updateFirebaseDocument('Exhibitions', params.id, { ExhibitionCurrentParticipants: parseInt(event.ExhibitionCurrentParticipants) + 1 , prizeProgress:priceProgressFee})
                                                        .then(() => {
                                                            // setHasJoined(true)

                                                            db.collection("Schedules").doc(params.id).collection('round_1').get().then((scheduleData)=>{
                                                                let secondUserObjectId = "";
                                                                scheduleData?.docs.map((sData,key)=>{
                                                                    if(sData.data()?.SecondUserName == ""){
                                                                        secondUserObjectId = sData.id;
                                                                    }
                                                                })
                                                    
                                                                if(secondUserObjectId == ""){
                                                                    var scoreObj = {
                                                                        
                                                                        FirstProfileImageUrl: userData.userProfileImageUrl,
                                                                        FirstUserId: userData.objectId,
                                                                        FirstUserName: userData.gamerTag,
                                                                        FirstUserScore: 0,
                                                                        SecondProfileImageUrl: "",
                                                                        SecondUserId: "",
                                                                        SecondUserName: "",
                                                                        SecondUserScore: 0,
                                                                        Winner: ""
                                                                    };
                                                                        
                                                                    db.collection("Schedules").doc(params.id).collection('round_1').add(scoreObj);
                                                                }
                                                                else{
                                                                    var scoreObj = { 
                                                                        SecondProfileImageUrl: userData.userProfileImageUrl, 
                                                                        SecondUserId: userData.objectId, 
                                                                        SecondUserName: userData.gamerTag, 
                                                                        SecondUserScore: 0 
                                                                    };
                                                                    db.collection("Schedules").doc(params.id).collection('round_1').doc(secondUserObjectId).update(scoreObj);
                                                                }
                                                            });

                                                            db.collection('ChatRooms').doc(params.id)
                                                                .get()
                                                                .then((doc) => {

                                                                    doc.ref.update({
                                                                        participants: firebase.firestore.FieldValue.arrayUnion({
                                                                            objId: currentUser.uid,
                                                                            email: currentUser.email,
                                                                            userName: gamerTag,
                                                                            userProfileImage: userData.userProfileImageUrl
                                                                        })

                                                                    })
                                                                        .then(() => {



                                                                            db.collection('User').doc(currentUser.uid).collection('ChatRoomIds')
                                                                                .add(
                                                                                    {
                                                                                        id: params.id,
                                                                                        isDeleted: false,
                                                                                        isDelivered: true,
                                                                                    }).then(() => {


                                                                                    })
                                                                                .catch((error) => {
                                                                                    console.log(error.message)
                                                                                    setLoading(false)

                                                                                })




                                                                        })
                                                                        .catch(e => {
                                                                            console.log(e.message)
                                                                        });



                                                                });

                                                                db.collection('Invites').where('InviteeId','==', userData.objectId).get().then((invite)=>{
                                                                    if(!invite.empty){
                                                                        var inviteDoc = invite.docs[0];
                                                                        var inviteData = inviteDoc.data();
                                                                        
                                                                        if(inviteData.JoinedEventId == "" || inviteData.JoinedEventId == null){
                                                                            db.collection('User').where('objectId', '==', inviteData.InviterId).get().then((inviterUser)=>{
                                                                                var inviterUserDoc = inviterUser.docs[0];
                                                                                var inviterUserData = inviterUserDoc.data();
                                                        
                                                                                var inviteCredit = 10;
                                                                                if("InviteCredits" in inviterUserData && inviterUserData.InviteCredits != ""){
                                                                                    inviteCredit = parseInt(inviterUserData.InviteCredits) + 10;
                                                                                }
                                                                               
                                                                                updateFirestoreDocument('User', inviteData.InviterId, {InviteCredits: inviteCredit}).then(() => {
                                                          
                                                                                    //Update userData balance in firebase userData document
                                                                                    updateFirebaseDocument('Users', inviteData.InviterId, {InviteCredits: inviteCredit})
                                                                                    .catch(e => {
                                                                                        console.log(e);
                                                                                        updateFirestoreDocument('User', inviteData.InviterId, {InviteCredits: inviterUserData.InviteCredits})
                                                                                    })
                                                                                
                                                                                }).catch(e=>{
                                                                                    console.log("error in firestore document", e)
                                                                                })
                                                        
                                                                                updateFirestoreDocument('Invites', inviteDoc.id, {JoinedEventDateTimeStamp: firebase.firestore.FieldValue.serverTimestamp(), JoinedEventID: params.id});
                                                                            })
                                                                        }
                                                                    }
                                                                })

                                                            setLoading(false)
                                                            setModalShow(false)
                                                        })


                                                })
                                                .catch(e => {
                                                    console.log(e)
                                                    setError(e.message)

                                                    setLoading(false)
                                                })
                                        })

                                        .catch(e => {
                                            console.log(e)
                                            setError(e.message)
                                            setLoading(false)
                                        })

                                })
                                .catch(e => {
                                    setError(e.message)

                                    console.log(e)
                                    setLoading(false)
                                })



                        } else {
                            console.log(e)
                            setError('Error Joining Event')

                            setLoading(false)
                        }

                    } else {

                        setError('Please deposit enough funds to join the event')

                        setLoading(false)
                    }
                }
                else
                    setError('Already joined the event')
            })
            .catch(e => {
                console.log(e.message)
            })

    }

    return (
        <>



            <>
                <ModalPopup
                    loading={loading}
                    show={modalShow}
                    error={error}
                    onHide={() => setModalShow(false)}
                />
                <ModalInviteCredit show={inviteCreditModalShow} onHide={()=>setInviteCreditModalShow(false)} handleJoinEvent={()=>handleJoinEvent()}  setUseInviteCreditsChecked={(checkBox)=>{setUseInviteCreditsChecked(checkBox)}} />

            </>
            <div className={`${isLoading && 'loading'} event-detail-container flex-column`}>
                <div className=' mb-4 position-relative overflow-hidden m-0 border-0 grid-item event-card'>
                    <div className={`sm-view position-absolute back-arrow`}>
                        <BackButton />
                    </div>

                    <div className='cover-img-wrapper' style={{minHeight: "inherit", height: "inherit"}}>
                        <img style={{minHeight: "inherit", height: "inherit"}} src={gameCoverArt} alt="" />
                    </div>

                    {
                        event?.ExhibitionConsole.indexOf('PlayStation', 0) !== -1 ?
                            <button className='ml-auto mr-0  text-light difficulty-btn ' style={{ backgroundColor: difficulty.playstation }} value=''>
                                <span>{event?.ExhibitionConsole}</span>
                            </button>

                            :

                            event?.ExhibitionConsole.indexOf('Xbox', 0) !== -1 ?
                                <button className='ml-auto mr-0  text-light difficulty-btn ' style={{ backgroundColor: difficulty.easy }} value=''>
                                    <span>{event?.ExhibitionConsole}</span>
                                </button>
                                :
                                <button className='ml-auto mr-0  text-light btn-danger difficulty-btn ' value=''>
                                    {event?.ExhibitionConsole}
                                </button>
                    }
                </div>
                <div className='event-info'>
                    <div className='lg-view flex-column'>
                        <div className='d-flex  align-items-center'>
                            <p className='event-detail-title text-light'>{event?.ExhibitionName}</p>
                            <div className='join-btn mr-0 ml-auto'>
                                <>
                                    {!loading && participantsList.length >= parseInt(event?.ExhibitionMaximumParticipants) ? <button className='btn' style={{cursor:'not-allowed', backgroundColor:'transparent', borderColor:'#fff', borderWidth:'1px'}}>Exhibition Full</button> :
                                        <>

                                            {!loading && hasJoined ? <button className='   btn  '>Registered</button> : !loading && !hasJoined ? <button disabled={loading} onClick={("InviteCredits" in userData) ? clickJoinEvent : handleJoinEvent} className='   btn  '>{loading ? 'Loading...' : 'Join Exhibition'}</button> : <button className='   btn  '>Loading...</button>}

                                        </>
                                    }


                                </>


                            </div>
                        </div>
                        <div className='d-flex card-category'>
                            <p className='cost  '>${event?.ExhibitionEntryFee?.toFixed(2)}</p>
                            <p className='ml-5  '>{event?.ExhibitionGame}</p>
                        </div>
                    </div>
                    <div className='sm-view'>
                        <div className='d-flex  flex-wrap align-items-center'>
                            <p className='event-detail-title text-light'>{event?.ExhibitionName}</p>
                            <p className='cost  ml-auto mr-0 text-right'>${event?.ExhibitionEntryFee?.toFixed(2)}</p>
                        </div>
                        <p className=''>{event?.ExhibitionGame}</p>
                    </div>
                    <div className='d-flex flex-wrap '>
                        <p className=' text-light event-description'>Below are the exhibition details and participants.<br></br>For additional details please reach out to the commissioner. </p>

                        <div className='d-sm-block d-md-block mt-2 mb-4 d-lg-none w-100'>
                            {!loading && participantsList.length >= parseInt(event?.ExhibitionMaximumParticipants) ? <button className=' w-100   btn '>Exhibition Full</button> :
                                <>

                                    {!loading && hasJoined ? <button className=' w-100  btn  '>Registered</button> : !loading && !hasJoined ? <button disabled={loading} onClick={("InviteCredits" in userData) ? clickJoinEvent : handleJoinEvent} className=' w-100   btn  '>{loading ? 'Loading...' : 'Join Exhibition'}</button> : <button className='w-100    btn  '>Loading...</button>}

                                </>
                            }

                        </div>

                    </div>
                    <div className='about-section'>
                        {
                            (event?.ExhibitionGameType == "Sport")?
                            <>
                            <p className=' text-light event-description'>Quarter Length: {event?.ExhibitionQuarterLength}</p>
                            <p className=' text-light event-description'>Difficulty: {event?.ExhibitionDifficulty}</p>
                            </>
                            :
                            (event?.ExhibitionGameType == "Pokemon VS")&&
                            <>
                            <p className=' text-light event-description'>Rule Set: {event?.ExhibitionRuleSet}</p>
                            <p className=' text-light event-description'>Battle Type: {event?.ExhibitionBattleType}</p>
                            </>

                        }
                        
                    </div>
                    <div className='mb-3 about-section'>
                        <h5 className='text-light mt-4 mb-4'>About</h5>
                        <div className='d-flex event-icon-container'>
                            <div className='d-flex  icon-item align-items-center flex-grow-1'>
                                <div className='icon-wrapper'>
                                    <div className='center'>
                                        <img src={schedule} alt="" />

                                    </div>
                                </div>
                                <div className='ml-3'>
                                    <div className='text-light f-20 about-upper-info'>{startDate} - {endDate}</div>
                                    <div className='text-light about-low-info'>Start Date - End Date</div>
                                </div>
                            </div>
                            <div className='d-flex  icon-item align-items-center flex-grow-1'>
                                <div className='icon-wrapper'>
                                    <div className='center'>
                                        <img src={participants} alt="" />

                                    </div>
                                </div>
                                <div className='ml-3'>
                                    <div className='text-light about-upper-info'>{participantsList.length}/{event?.ExhibitionMaximumParticipants}</div>
                                    <div className='text-light about-low-info'>Joined/Total Participants</div>
                                </div>
                            </div>
                            <div className='d-flex  icon-item align-items-center flex-grow-1'>
                                <div className='icon-wrapper'>
                                    <div className='center'>
                                        <img src={trophy} alt="" />

                                    </div>
                                </div>
                                <div className='ml-3'>
                                    <div className='text-light about-upper-info'>${event?.ExhibitionTotalPrizes?.toFixed(2)}</div>
                                    <div className='text-light about-low-info'>Prize</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className='commissioner-section'>
                            <h5 className='text-light mt-4 mb-4'>Creator</h5>
                            <div className='d-flex commissioner-icon-container space-light'>
                                <div className='d-flex flex-grow-1'>
                                    {event?.ExhibitionCommissionerId && event?.ExhibitionCommissionerId !== null ? <>
                                        <div id={event?.ExhibitionCommissionerId} ref={commisionerId} className='mr-3 align-items-center'>
                                            <div onClick={() => { history.push(`/user/${event?.ExhibitionCommissionerId}`) }} className='commissioner-icon-wrapper'>
                                                <div className='center'>
                                                    <img src={!!event?.ExhibitionCommissionerProfile && event?.ExhibitionCommissionerProfile !== "NONE" ? event?.ExhibitionCommissionerProfile:UserImage} alt="" />
                                                    {/* <img src={event?.ExhibitionCommissionerProfile} alt="" /> */}

                                                </div>
                                            </div>
                                            <p className='text-light text-center mt-2'>{event?.ExhibitionCommissioner}</p>


                                        </div>

                                    </> :
                                        <></>}
                                    

                                </div>

                            </div>
                        </div>
                        <div className='commissioner-section'>
                            <h5 className='text-light mt-4 mb-4'>Commissioner</h5>
                            <div className='d-flex commissioner-icon-container space-light'>
                                <div className='d-flex flex-grow-1'>
                                    <div id={event?.ExhibitionSecondCommissionerId} ref={secondCommissionerId} className='mr-3 align-items-center'>
                                        <div className='commissioner-icon-wrapper'>
                                            <div className='center'>
                                                <img src={logo} alt="" />

                                            </div>
                                        </div>
                                        <p className='text-light text-center mt-2'>ClutchTime</p>


                                    </div>
                                    {event?.ExhibitionSecondCommissionerId && event?.ExhibitionSecondCommissionerId !== 'None' ? <>
                                        <div id={event?.ExhibitionSecondCommissionerId} ref={secondCommissionerId} className='mr-3 align-items-center'>
                                            <div className='commissioner-icon-wrapper'>
                                                <div className='center'>
                                                    <img src={nike} alt="" />

                                                </div>
                                            </div>
                                            <p className='text-light text-center mt-2'>{event?.ExhibitionSecondCommissionerId}</p>


                                        </div>

                                    </> :
                                        <></>}

                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </>
    );
}


export default EventSection;