import firebase from "firebase";
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

// Initialize Firebase
// Save as environment variables
const app = firebase.initializeApp({
                apiKey: "AIzaSyADnLLxOCqBheJ642B_wS5rI4W9pTrQ0wc",
   				authDomain: "clutchtime-af0db.firebaseapp.com",
  				databaseURL: "https://clutchtime-af0db.firebaseio.com",
  				projectId: "clutchtime-af0db",
  				storageBucket: "clutchtime-af0db.appspot.com",
  				messagingSenderId: "99393799819",
  				appId: "1:99393799819:web:bc815559e5a4cf0dd3b68e",
  				measurementId: "G-6QDM5MGRY5"
});

const db = app.firestore()
const realTimeDatabase = app.database();
const auth =app.auth()
const storage =app.storage()
 export {db , auth ,realTimeDatabase,storage}