import React, { useRef, useState } from 'react';
import './signup.css';
import trophy from '../../images/trophy.svg';
import password from '../../images/password.svg';
import mail from '../../images/mail.svg';
import user from '../../images/user.svg';
import { pushData, updateDocument, setRealtimeDoc, setFirestoreDocument } from "../../utility/helper";
import firebase from "firebase";
import { auth, db } from '../../firebase/firebase';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReferralCode from './referralcode';

function Signup(props) {
    const usernameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [username, setUsername] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [showReferralCodeModal, setReferralCodeModal] = useState(false);
    let history = useHistory()

    async function signUp(e) {
        if (username.trim() == "") {
            setError('Please enter Game Tag')
            return ""
        }else if (emailRef.current.value.trim() == "") {
            setError('Please enter Email')
            return ""
        }else if (passwordRef.current.value.trim() == "") {
            setError('Please enter Password')
            return ""
        }
        setError('')
        setLoading(true)

        const users = await db.collection('User').get();

        var username_exists_satus = false;
        var email_exists_status = false;
        users.forEach(user => {
            const usrData = user.data();
            if (usrData.userName && usrData.userName.toLowerCase() == username.toLowerCase()) {
                username_exists_satus = true;
            }
            else if (usrData.gamerTag && usrData.gamerTag.toLowerCase() == username.toLowerCase()) {
                username_exists_satus = true;
            }

            if (usrData.email?.toLowerCase() == emailRef.current.value.toLowerCase()) {
                email_exists_status = true;
            }
        })

        if (username_exists_satus) {
            setLoading(false);
            setError('Username already exists. Please use another one.');

            return "";
        }

        if (email_exists_status) {
            setLoading(false);
            setError('Email already exists. Please use another one.');

            return "";
        }
        
        await props.setReferralCodeData({email: emailRef.current.value, password: passwordRef.current.value, username: username});
        await props.onSignUp('referralCode');


    }

    return (
        <>

            <form className="form">
                <div className={`header-content `}>
                    <img className="form-image" src={trophy} alt="" />
                    <p className="form-title pt-4">ClutchTime</p>
                    <p className='form-text f-18'>Please register your details to continue
                        with ClutchTime</p>
                </div>
                <p className="text-danger">{error}</p>

                <div className="input-group">
                    <input ref={usernameRef} onChange={(e) => setUsername(e.target.value)} name="username"
                        style={{ backgroundImage: `url(${user})` }} type="text"
                        placeholder="Gamer Tag" />
                    <input ref={emailRef} name="email" style={{ backgroundImage: `url(${mail})` }} type="Email"
                        placeholder="Email" />
                    <input ref={passwordRef} name="password" style={{ backgroundImage: `url(${password})` }}
                        type="Password" placeholder="Password" />

                </div>

                <button disabled={loading} style={{ background: loading ? '#ffffff' : '' }} type="button" onClick={signUp}>
                    <span className='form-btn'>Sign Up</span></button>
            </form>
            
        </>
    );
}

export default Signup;


